define("discourse/plugins/discourse-activity-pub/discourse/routes/admin-plugins-activity-pub-actor", ["exports", "@ember/array", "discourse/lib/ajax", "discourse/routes/discourse", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _array, _ajax, _discourse, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class AdminPluginsActivityPubActorRoute extends _discourse.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queryParams", {
        order: {
          refreshModel: true
        },
        asc: {
          refreshModel: true
        },
        model_type: {
          refreshModel: true
        }
      });
    }
    model(params) {
      const searchParams = new URLSearchParams();
      Object.keys(this.queryParams).forEach(param => {
        if (params[param]) {
          searchParams.set(param, params[param]);
        }
      });
      return (0, _ajax.ajax)(`${_activityPubActor.actorAdminPath}?${searchParams.toString()}`);
    }
    setupController(controller, model) {
      controller.setProperties({
        actors: (0, _array.A)((model.actors || []).map(actor => {
          return _activityPubActor.default.create(actor);
        }))
      });
    }
  }
  _exports.default = AdminPluginsActivityPubActorRoute;
});